import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Seo from './../../components/Seo'
import Layout from './../../components/Layouts/Default'

import meta from './../../meta'

export default function Blog({ data }) {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <div className="blog-posts basic">
        <Seo meta="blog" />
        {posts
          .filter((post) => post.node.frontmatter.meta.length > 0)
          .map(({ node: post }) => {
            const { title, subtitle, thumb } = meta[post.frontmatter.meta]

            return (
              <div className="blog-description" key={post.id}>
                <h3 className="h3">
                  <Link to={'/blog/' + post.frontmatter.slug}>{subtitle}</Link>
                </h3>
                <h6 className="h6">{post.frontmatter.date}</h6>
                <div className="thumb-image">
                  <span className="helper">
                    <img src={thumb} alt={`${title} post thumbnail`} />
                  </span>
                </div>
                <div className="description">
                  <div className="blog-post-preview" key={post.id}>
                    <p>{post.excerpt}</p>
                    <p className="click-for-more">
                      <Link to={'/blog/' + post.frontmatter.slug}>{`Read more...`}</Link>
                    </p>
                  </div>
                </div>
                <hr className="end-description" />
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            meta
            slug
          }
        }
      }
    }
  }
`
